body{
    background-image: url('../Imagenes/wallpaperbetter.com_1920x1200\ \(1\).jpg');
    background-position: center;
    background-attachment: fixed;
   
}

.nav{
    width: 100%;
}
.pElab{
  font-weight: bolder;
  color: aliceblue;
}
@media screen and (max-width: 640px) {
    .nav {
      background: blue;
    }
  }
  
  @media screen and (min-width: 640px) and (max-width: 1280px) {
    .nav {
      background: red;
    }
  }
  
  @media screen and (min-width: 1280px) {
    .nav {
      background: green;
    }
  }