/* .fondo{
    width:100vw;
    height:100vh; 
    background-image: url('../Imagenes/wallpaperbetter.com_2880x1620.jpg');
    background-position: center;
    
    

} */

@font-face{
    font-family: "Pokemon1";
    src:url(../Fonts/Pokemon\ Solid.ttf)
}
.fondo{
    display: flex;
    align-items: center;
    justify-content: center;
}
.video{
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}
 
 /* .ready,.goHome{
    position: absolute;
    color:#ffcc03;
    margin-left: 1em;
    -webkit-text-stroke: 1px rgb(0, 106, 255); 
    font-family: Pokemon1;
    font-size: 60px
    
}  */
.home{
   
     position: relative;
   margin-top: 80vh;
     /* left:40vw; */
    

}
 
   .goHome{
    text-align: center;
    font-family: Pokemon1;
    color: yellow;
    -webkit-text-stroke: 1px rgb(0, 106, 255); 
   text-decoration: none;
   background-color:transparent;
   border-color: transparent;
    font-size: xx-large;
   }
  
.ready{
    right: 5%;
    justify-content: center;
    position: fixed;
    text-align: center;
    color: aliceblue;
    font-family: Pokemon1;
   
    font-size: 30px;
    -webkit-text-stroke: 1px rgb(0, 106, 255); 
    color: yellow;
    margin-top: -1em;
    text-decoration: none;
}


@media screen and (max-width: 640px) {
    
    .video{
left: -145vw;

    }

    .ready{
        font-size:medium;
        -webkit-text-stroke: 0.5px rgb(0, 106, 255);
        text-align: center;
        line-height: 3em;
        margin-left: 0em;
        padding: 1em;
    }
   
    .home{
        margin-top: 70vh;
    }
  }