.pagina{
    background-color: black;
    color: aliceblue;
    border-style: solid;
    border-color: aliceblue;
    border-width: 3px;
}

.pagina:hover{
    transform:scale(1.5);
    color:rgb(205, 0, 0);
}

@media screen and (max-width: 640px) {
    .divPrincipal {
   
     margin-left: -2em;
     
    }
   
  }
  
  @media screen and (min-width: 640px) and (max-width: 1280px) {
    .menu {
      background: red;
    }
  }
  
  @media screen and (min-width: 1280px) {
    .menu {
      background: green;
    }
  }