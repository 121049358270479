.divPrincipal{
    display: flex;
}

.input{
    background-color: rgb(5, 189, 5);
    font-family: Pokedex;
    color: aliceblue;
    box-shadow: 0 0 20px rgb(106, 255, 0);
    width: 150px;
    border-style: solid;
    border-bottom: 3px;
    border-color: black;
    font-size: x-small;
    margin-left: 2em;
}



.button{
    background-color:  rgb(5, 113, 255);
    color: aliceblue;
    width: 80px;
    height: 80px;
    padding: 5px;
    border-radius: 50%;
    border-color: aliceblue;
    border-width: 8px;
    margin-top:0em;
   
}
.button:hover{
    box-shadow: 0 0 40px rgb(68, 196, 255)
    
}

.btn1{
    background-color: red;
    width: 15px;
    height: 15px;
    border-radius: 50%;
   top: 10px;
}

.btn2{
    background-color: rgb(255, 238, 0);
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.btn3{
    background-color: rgb(0, 255, 30);
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.lights{
   margin-top: 0em;
   margin-left: 0.5em;
}

.inputContainer{
    margin-top: 1.5em;
    margin-left: -4em;
    
}

@media screen and (max-width: 1050px){
.divPrincipal{
    display:flex;
    flex-direction: column;
    text-align: left;
    justify-content: left;
    margin-top: 1em;
    position: relative;
   
}

.inputContainer{
    position: absolute;
    margin-left: 3.9em;
    
    
}
.lights{
    margin-top: -5em;
    margin-left: 5em;
}

}