.divPrincipal{
    background-color: rgb(170, 0, 0);
    border-bottom: 5px;
    border-color: black;
    border-style: solid;
    height: 120px;
    width: 100vw;

}

.button1{
    background-color: rgb(224, 189, 11);
    color: black;
height: 85px;
width: 85px;
border-radius: 50%;
border-style: solid;
border-width: 4px;
border-color: rgb(0, 0, 0);
font-size: smaller;
margin-right: 1em;
margin-top: -1em;
text-align: center;
}
.button1:hover{
    box-shadow: 0 0 40px rgb(255, 221, 0)
    
}
.button2{
    border-color: black;
    border-style: solid;
    border-width: 2px;
    border-radius: 4px;
}
.create{
text-align: right;
margin-top:-2em
}

.back{
    margin-left: 31em;
    margin-top: -2em;
}
.button2:hover{
    color: aliceblue;
    background-color: black;
}
.filterAbc,.filterType,.filterStrength,.created{
    background-color: rgb(5, 113, 255);
    color: aliceblue;
    border-style: solid;
    border-color: black;
    border-width: 3px;
    border-radius: 4px;
    height: 50px;
    margin-top: 2em;
    display:table-row-;
    text-align:center
}

.filterAbc:hover,.filterType:hover,.filterStrength:hover,.created:hover{
    transform: scale(1.1);

}
.contenedorSearch{
    display: flex;
    margin-top: -6em;
    
}
.searchBar{
    text-align: left;
   
}

@media screen and (max-width: 1050px) {
    .divPrincipal{
      display: flex;
      flex-direction: column;
      height:450px;
      width: 100%vw;
      
     
    }
    .contenedorSearch{
        display: flex;
        flex-direction: column;
    }
    .back{
        display: flex;
        flex-direction: column;
        margin-left: 0em;
        margin-top: 0em;
    }
    .button1{
        display: flex;
        flex-direction: column;
        margin-top: 3em;
        text-align: center;
        justify-content: center;
        align-content: center;
        margin-right: 0em;
        margin-left:80%;
        
    }

    .contenedorSearch{
        display: flex;
        flex-direction: column;
        align-self:left;
        
    }

    

  }
  