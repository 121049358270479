.divPrincipal{
  background-image: url("../Imagenes/PokebolaCerrada.png");
  background-repeat: no-repeat;
  background-size: 80%;
  background-position:center;
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 400px;
  height: 400px;
  margin-top: 5em;

  
 
}

.divPrincipal:hover,.divPrincipal:active{
  transition: 0.3s;
  background-image: url("../Imagenes/pokeball1.png");
  
}

.img{
  transition:0s;
  visibility: hidden;
  margin-top:-11em

}
.divPrincipal:hover .img{
  visibility: visible;
  transition: 0.5s;
}
.divPrincipal:active .img{
  visibility: visible;
  transition: 0.5s;
}

.divPrincipal:hover  .idId{
  visibility: visible;
  transition: 0.5s;
}
.divPrincipal:active  .idId{
  visibility: visible;
  transition: 0.5s;
}

.container{
  display: flex;
}

.idContainer{
  text-align: center;
  margin-left: 3em;
  visibility: hidden;
}

.idContainer2{
  margin-top: 7em;
  justify-content: center;
}

.idId2{
  color: black;
  font-size: xx-large;
  font-weight:bolder
}

.nameContainer{
text-align: center; 

margin-top: 1em;


}

.typeContainer{
  text-align: center;
  max-width: 100px;
  margin-left: 1.5em;
  margin-top:-1.5em;
}

.type{
  margin-bottom: -0.5em;
}


.idId{
  color:aliceblue;
 border-radius: 50%;
 width: 40px;
height: 30px;
  background-color: grey;
  border-style: solid;
  border-color: aliceblue;
  size: 20px;
  
}

.name{
  border-style: solid;
  border-color: aliceblue;
  border-width: 3px;
  border-radius: 35px;
  background-color: black;
  color: aliceblue;
  padding: 3px;
  
}

.type{
  border-style: solid;
  border-color: aliceblue;
  border-width: 3px;
  border-radius: 35px;
  background-color: black;
  color: aliceblue;
  padding: 1px;
  list-style-type: none;
  margin-top: 10px;

}

.navLink{
text-decoration: none;
}

.img:hover, .img:active{
  transform: scale(2);
  
}


