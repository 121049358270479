@font-face{
    font-family: "Pokedex";
    src:url(../Fonts/Pokemon\ GB.ttf)
}
 
 .divPrincipal{
    background-image: url(../Imagenes/pokedex.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    grid-template-rows:auto;
    border-style: solid;
    border-width: 5px;
    border-color: transparent;
    height: 100vh;
    width: 100vw;
    align-content: center;
    justify-content: center;
    
   
 }

 .nameContainer{

    border-style: solid;
    border-width: 3px;
    border-color: transparent;
    grid-row: 3/3;
    height: 15vh;
    display: flex;
   justify-content:center;
   
   
  
    
  
   
 }

 .name{
    font-family: Pokedex;
    font-size: small;
    color: black;
    position: relative;
    align-self:flex-start;
    justify-content: center;
   
    
 }
 .idContainer{
  
   
    grid-row: 1/1;
    border-style: solid;
    border-width: 3px;
    border-color: transparent;
    height: 30vh;
 }
 
 .id{
    color: black;
    font-family: Pokedex;
    margin-top: 13em;
    margin-left: -5em;
   
    
 }

 
.img{
  object-fit: contain;
  width: 100%;
  height: 100%;
  margin-left: -2em;
   
}

 .imgContainer{

    height:17vh; 
    border-style: solid;
    border-width: 3px;
    border-color: transparent;
    grid-row: 2/2;

}


.info {
    text-align: right;
    line-height:20px;
    border-style: solid;
    border-radius: 10px 10px 10px 35px;
    border-color: rgb(209, 216, 222);
    border-width: 5px;
    color: aliceblue;
    background-color: rgb(5, 146, 64);
    padding: 7px;
    width:auto;
    height:max-content;
    font-family: Pokedex;
    font-size: small;
    position:sticky;
    border-style: solid;
    border-width: 3px;
    border-color: transparent;
    grid-row: 4/4;
    text-align: right;
    text-justify: auto;
    justify-content: center;
    display:inline-block;
    list-style-type: none;
    align-self: start;
   

}



.form{
   width: 350px;
   height: 400px;
   margin-top: -25em;
   margin-left: 3em;
   border-style: solid;
   border-color: transparent;
   background-color: black;
   color: aliceblue;
   text-align: left;
   padding:10px;
   opacity:0.7
}

.bts{
   grid-row: 5/5;
   height: 5vh;
}


  @media (max-width: 550px) {
.divPrincipal{
   background-size: auto;
}
.idContainer{
   height: 37vh;
display: flex;
justify-content: center;
align-items: end;

}
.id{
 position: absolute;
margin-bottom: 0em
 
 

   
}
.imgContainer{
height: 10vh;   
}
.nameContainer{
   height: 10vh;
}
   /* .divPrincipal {
   flex-direction: initial;
   }
   .nameContainer {
     height: auto;
     font-size: 1.2em;
     display: flex;
   }
   .imgContainer {
     height: 70px;
     display: flex;
     justify-content: center;
      margin-top: 5em;
   }
   .info {
     font-size: xx-small;
    
     display: flexbox;
   }
   .form {
     width: 90%;
     height: auto;
     margin-left: 0;
   } */
 }